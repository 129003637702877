import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../components/UIComponents/icons/Warning";
import "../../../../App.css";
import "./SubscriptionDetails.css";
import Toast from "../../../../components/UIComponents/tools/Toast";

const SubscriptionDetails = () => {
    const { subscriptionId } = useParams();
    const navigate = useNavigate();
    const [subscription, setSubscription] = useState(null);
    const [price, setPrice] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState("");
    const [messageStatus, setStatus] = useState('green');
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        fetchSubscriptionDetails();
    }, []);

    const fetchSubscriptionDetails = async () => {
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/${subscriptionId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error("Fehler beim Abrufen des Abonnements");
            } else {
                const data = await response.json();
                setSubscription(data);

                let formattedPrice = data.price.toFixed(2).replace('.', ',');
                setPrice(formattedPrice);

                setError(false);
                setLoading(false);
            }
        } catch (error) {
            setError(true);
            setLoading(false);
        }
    };

    const handleTermination = async (event) => {
        event.preventDefault();
        try {
            const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/${subscriptionId}/terminate`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                const text = await response.text();
                if (text === "Subscription with no auto renew or is already terminated.") {
                    showToast('Das Abonnement ist bereits gekündigt oder läuft automatisch aus.', 'error');
                }
                else if (text === "Termination not possible because of termination limit one week before enddate."){
                    showToast('Kündigung nicht möglich, da Kündigungsfrist überschritten wurde.', 'error');
                }
                else {
                    throw new Error("Fehler bei der Kündigung");
                }
            } else {
                fetchSubscriptionDetails();
                showToast(`Abonnement wurde zum ${subscription.convertedEndDate} gekündigt.`, 'success');
            }
        } catch (error) {
            showToast('Fehler beim Kündigen des Abonnements. Bitte versuchen Sie es erneut.', 'error');
        }
    };

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Abonnement nicht gefunden</h1>
                    <p>Das Abonnement konnte nicht abgerufen werden. Bitte überprüfen Sie Ihre Berechtigung oder versuchen Sie es später erneut.</p>
                    <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                        Zurück zur Übersicht
                    </button>
                </div>
            </div>
        );
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <div>
                    <h1>Details zum Abonnement - {subscription.title}</h1>
                    <div className="action-card">
                        <h2>Allgemeine Details</h2>
                        <p><strong>Abonnement: </strong><a href={`/myaccount/subscriptions/contract/${subscription.code}`}>{subscription.code}</a></p>
                        <p><strong>Name: </strong>{subscription.title}</p>
                        <p><strong>Startdatum: </strong>{subscription.convertedStartDate}</p>
                        <p><strong>Enddatum: </strong>{subscription.convertedEndDate}</p>
                        <p><strong>Gesamtpreis: </strong>{price} €</p>
                        <p><strong>Status: </strong>{subscription.payed ? "Bezahlt" : "Nicht bezahlt"}</p>
                        <p><strong>Verlängerung: </strong>{subscription.autoRenew ? `Verlängert sich automatisch am ${subscription.convertedEndDate}` : `Gekündigt zum ${subscription.convertedEndDate}`}</p>
                    </div>

                    <div className="spacer"></div>

                    <div className="action-card">
                        <h2>Abonnement kündigen</h2>
                        <p>Sie können Ihr Abonnement zum nächstmöglichen Zeitpunkt ({subscription.convertedEndDate}) kündigen. Bitte bestätigen Sie die Kündigung.</p>
                        <form onSubmit={handleTermination}>
                            <div className="termination-aknowledge">
                                <div className="termination-aknowledge-input-labelwrapper">
                                    <label className="termination-aknowledge-label" htmlFor="aknowledge">
                                        <p>
                                            Ich möchte dieses Abonnement zum nächstmöglichen Zeitpunkt kündigen. Nach dem Enddatum ({subscription.convertedEndDate}) werde ich keinen Zugang mehr zu den von mir erstellten Umfragen und Daten haben.
                                        </p>
                                    </label>
                                </div>
                                <div className="termination-aknowledge-input-inputwrapper">
                                    <input
                                        className="termination-aknowledge-input"
                                        type="checkbox"
                                        name="aknowledge"
                                        required
                                    />
                                </div>
                            </div>
                            <button className="btn-danger">Abonnement kündigen</button>
                            <p className={`message ${messageStatus}`} id="message">{message}</p>
                        </form>
                    </div>

                    <div className="spacer3"></div>

                    <button className="btn btn-primary" onClick={() => navigate(`/myaccount/subscriptions`)}>
                        Zurück zur Übersicht
                    </button>
                </div>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default SubscriptionDetails;
