import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import './Login.css';

import Toast from "../../../components/UIComponents/tools/PublicToast";
const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [mfaSessionCode, setSessionCode] = useState('');
    const [totpCode, setTotpCode] = useState('');
    const [recoveryCode, setRecoveryCode] = useState('');
    const [message, setMessage] = useState('');
    const [messageStatus, setStatus] = useState('green');
    const [ip, setIP] = useState('');
    const [fingerprint, setFingerprint] = useState('')
    const [action, setAction] = useState('');
    const [actionText, setActionText] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const [MFAEnabled, setMFAEnabled] = useState(false);
    const [RecoveryEnabled, setRecoveryEnabled] = useState(false);

    const from = location.state?.from?.pathname || '/';

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (fingerprint === "") {
            setFingerprint("notfound");
        }

        try {
            await getIP();
            const response = await fetch('https://api.onesurvey.de/auth/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    username,
                    password,
                    ipAdress: ip,
                    browserFingerprint: fingerprint
                }),
            });

            if (response.ok) {
                const text = await response.text();

                if (text.startsWith("MFAACTIVATED")){
                    const parts = text.split("_");
                    setSessionCode(parts[1]);
                    setMFAEnabled(true);
                    showToast('MFA aktiviert. Bitte geben Sie Ihren TOTP-Code ein.', 'info');
                }
                else{
                    sessionStorage.setItem('OSACCESS', text);
                    navigate(from);
                }
            } else {
                const text = await response.text();
                if (text === 'Please verify E-Mail-Adress') {
                    setAction('/resend-verify-code');
                    setActionText('Jetzt Account verifizieren');
                    setMessage('Bitte verifizieren Sie Ihre E-Mail-Adresse.');
                }
                else if (text == 'Account deactivated'){
                    navigate('/sites/account-deactivated');
                }
                else if (text == 'Login failed'){
                    showToast('Anmeldung fehlgeschlagen. Passwort oder Benutzername falsch.', 'error');
                }
                else if (text == 'Account temporary blocked due to many login fails.'){
                    showToast('Zu viele fehlgeschlagene Anmeldungen. Account für 5 Minuten gesperrt.', 'error');
                }
            }
        } catch (error) {
            showToast('Fehler beim Login. Bitte versuchen Sie es erneut.', 'error');
        }
    };

    const handleSubmitMFA = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://api.onesurvey.de/auth/totp-validate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: username,
                    totpCode: totpCode,
                    sessionCode: mfaSessionCode
                }),
            });

            if (response.ok) {
                const text = await response.text();
                sessionStorage.setItem('OSACCESS', text);
                navigate(from);

            } else {
                const text = await response.text();
                if (text === 'TOTP not configured for this account') {
                    showToast('TOTP ist für diesen Account nicht konfiguriert.', 'error');
                    setMFAEnabled(false);
                }
                else if (text === 'Invalid TOTP code') {
                    showToast('TOTP Code nicht korrekt. Falls Sie Probleme mit Ihrem MFA haben, kontaktieren Sie uns bitte.', 'error');
                }
                else if (text === 'No active Session'){
                    showToast('Sie haben zu lange gebraucht. Bitte melden Sie sich neu an.', 'error');
                    setMFAEnabled(false);
                }
                else{
                    showToast(text, 'error');
                }
            }
        } catch (error) {
            showToast('Fehler bei der TOTP Authentifizierung. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const handleSubmitRecovery = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://api.onesurvey.de/auth/recovery-validate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: username,
                    recoveryCode: recoveryCode,
                    sessionCode: mfaSessionCode
                }),
            });

            if (response.ok) {
                const text = await response.text();
                sessionStorage.setItem('OSACCESS', text);
                navigate(from);

            } else {
                const text = await response.text();
                if (text === 'TOTP not configured for this account') {
                    showToast('TOTP ist für diesen Account nicht konfiguriert.', 'error');
                    setMFAEnabled(false);
                }
                else if (text === 'Invalid Recoverycode') {
                    showToast('Der Wiederherstellungscode ist leider nicht korrekt.', 'error');
                }
                else if (text === 'No active Session'){
                    showToast('Sie haben zu lange gebraucht. Bitte melden Sie sich neu an.', 'error');
                    setMFAEnabled(false);
                }
                else{
                    showToast(text, 'error');
                }
            }
        } catch (error) {
            showToast('Fehler bei der TOTP Authentifizierung. Bitte laden Sie die Seite neu.', 'error');
        }
    };

    const getIP = async () => {
        try {
            const res = await fetch('https://api.ipify.org/?format=json');
            const data = await res.json();
            setIP(data.ip);
        } catch (error) {
            console.error('Error fetching the IP:', error);
        }
    };

    const checkMaintenanceMode = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/portal/app-status', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                const status = await response.json();
                if (status.value === "true"){
                    showToast('Aufgrund von Wartungsarbeiten ist der Login derzeit gesperrt. Bitte versuchen Sie es in 15 Minuten erneut.', 'info');
                    setTimeout(()=>{navigate('/sites/maintenance');}, 3000)
                }
            }
            else{
                console.log("Error fetching Portal APIs");
            }
        } catch (error) {
            console.log("Error fetching Portal APIs");
        }
    }

    useEffect(() => {
        document.title = 'OneSurvey - Login';
        getIP();
        checkMaintenanceMode();
        FingerprintJS.load().then(fp => {
            fp.get().then(result => {
                setFingerprint(result.visitorId);
            });
        });
    }, []);

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div className="app-login">
            <div className="login">
                <div className="login-container">
                    <div className="login-wrapper">
                        <img
                            src="https://appcdn.onesurvey.de/static/content/OneSurvey-FullLogo-1020.png"
                            width="250px"
                            alt="OneSurvey Logo"
                        />
                        <h2>Bei Ihrem OneSurvey Account anmelden</h2>
                        {
                            MFAEnabled ?

                                <div>
                                    {
                                        RecoveryEnabled ?

                                            <form onSubmit={handleSubmitRecovery}>
                                                <label>
                                                    Ihr Recovery Code:
                                                    <input
                                                        type="text"
                                                        name="text"
                                                        value={recoveryCode}
                                                        onChange={(e) => setRecoveryCode(e.target.value)}
                                                        placeholder="XXXX-XXXX-XXXX"
                                                        required
                                                    />
                                                </label>
                                                <button id="loginbtn" type="submit">
                                                    Anmelden
                                                </button>
                                                <p>
                                                    Sie haben noch keinen OneSurvey Account?{' '}
                                                    <a href="/create-account"><br/>
                                                        Jetzt kostenlos erstellen
                                                    </a>
                                                </p>
                                                <p className={`login-message ${messageStatus}`}>
                                                    {message} <a href={action}>{actionText}</a>
                                                </p>
                                            </form>
                                            :
                                            <form onSubmit={handleSubmitMFA}>
                                                <label>
                                                    Ihr TOTP-Code:
                                                    <input
                                                        type="text"
                                                        name="text"
                                                        value={totpCode}
                                                        onChange={(e) => setTotpCode(e.target.value)}
                                                        placeholder="XXXXXX"
                                                        required
                                                    />
                                                </label>
                                                <a onClick={()=>{setRecoveryEnabled(true)}}>
                                                    Sie können nicht auf Ihren TOTP-Code zugreifen?
                                                </a>
                                                <button id="loginbtn" type="submit">
                                                    Anmelden
                                                </button>
                                                <p>
                                                    Sie haben noch keinen OneSurvey Account?{' '}
                                                    <a href="/create-account"><br/>
                                                        Jetzt kostenlos erstellen
                                                    </a>
                                                </p>
                                                <p className={`login-message ${messageStatus}`}>
                                                    {message} <a href={action}>{actionText}</a>
                                                </p>
                                            </form>

                                    }
                                </div>
                                :
                                <div>
                                    <form onSubmit={handleSubmit}>
                                        <label>
                                            Ihre E-Mail-Adresse
                                            <input
                                                type="text"
                                                name="email"
                                                value={username}
                                                onChange={(e) => setUsername(e.target.value)}
                                                placeholder="E-Mail"
                                                required
                                            />
                                        </label>
                                        <label>
                                            Passwort
                                            <input
                                                type="password"
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                                placeholder="Passwort"
                                                required
                                            />
                                        </label>
                                        <a href="/reset-password">
                                            Passwort vergessen?
                                        </a>
                                        <button id="loginbtn" type="submit">
                                            Anmelden
                                        </button>
                                        <p>
                                            Sie haben noch keinen OneSurvey Account?{' '}
                                            <a href="/create-account"><br/>
                                                Jetzt kostenlos erstellen
                                            </a>
                                        </p>
                                        <p className={`login-message ${messageStatus}`}>
                                            {message} <a href={action}>{actionText}</a>
                                        </p>
                                    </form>
                                </div>
                        }

                    </div>
                </div>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
};

export default Login;
