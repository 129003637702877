import React, { useState, useEffect } from "react";
import {useNavigate, useParams} from "react-router-dom";
import Header from "../../../../../components/Header";
import LoadingScreen from "../../../../../components/UIComponents/screens/LoadingScreen";
import WarningIcon from "../../../../../components/UIComponents/icons/Warning";
import SelectIcon from "../../../../../components/UIComponents/icons/Select";
import "../../../../../App.css";

const ContractDetails = () => {
    const { contractCode } = useParams();
    const navigate = useNavigate();
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const accessToken = sessionStorage.getItem('OSACCESS');

    useEffect(() => {
        const fetchContractSubscriptions = async () => {
            try {
                const response = await fetch(`https://api.onesurvey.de/v1/subscriptions/contract/${contractCode}`,{
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    }
                });

                if (!response.ok) {
                    throw new Error("Fehler beim Abrufen der Abonnements");
                } else {
                    const data = await response.json();
                    setSubscriptions(data);
                    setError(false);
                    setLoading(false);
                }
            } catch (error) {
                setError(true);
                setLoading(false);
            }
        };

        fetchContractSubscriptions();
    }, []);

    if (loading) {
        return <LoadingScreen />;
    }

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                    <h1>Dieser Vertrag wurde leider nicht gefunden.</h1>
                    <p>Es konnten zu diesem Vertrag keine Informationen gefunden werden.</p>
                    <button className="btn btn-primary" onClick={() => navigate('/myaccount/subscriptions')}>
                        Zurück
                    </button>
                </div>
            </div>
        );
    }

    return (
        <div>
            <Header />
            <div className="app-container">
                <div>
                    <h1>Mein Vertrag {contractCode}</h1>
                    <p>Hier finden Sie eine Übersicht mit Historie dieses Vertrags.</p>
                    <div className="spacer"></div>
                    <div className="list">
                        {
                            subscriptions.length > 0 ? (
                                subscriptions.map(subscription => (
                                    <div key={subscription.id} className="list-item">
                                        <div id="item-description">
                                            <p className="secondaryText">{subscription.title}</p>
                                            <h2>{subscription.convertedStartDate} - {subscription.convertedEndDate} </h2>
                                                {
                                                    subscription.status ?
                                                        <p>
                                                            Status: {subscription.status ? "Derzeit aktiv" : "Ausgelaufen zum" + subscription.convertedEndDate}<br/>
                                                            Verlängerung: {subscription.autoRenew ? "Verlängert sich automatisch" : "Vertrag wurde gekündigt zum " + subscription.convertedEndDate}
                                                        </p>
                                                        :
                                                        <p></p>
                                                }
                                                {
                                                    subscription.productCode == 'OSRVY0001' ? <div><a href="/e-shop">Jetzt upgraden</a><div className="spacer"></div></div> : <p></p>
                                                }
                                        </div>
                                        <div id="item-controls">
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div>
                                    <WarningIcon size={"90px"} fill={"none"} color={"#3a3a3a"} />
                                    <h2>Keine Abonnements gefunden</h2>
                                    <p>Es sind keine Abonnements verfügbar. Besuchen Sie unseren Shop, um die besten Angebote zu entdecken!</p>
                                    <button className="btn btn-primary" onClick={() => navigate('/e-shop')}>Zum Shop</button>
                                </div>
                            )
                        }
                    </div>
                </div>
                <button className="btn btn-primary" onClick={() => navigate(`/myaccount/subscriptions/`)}>Zurück</button>
            </div>
        </div>
    );
}

export default ContractDetails;