import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../../../components/Header";
import LoadingScreen from "../../../../components/UIComponents/screens/LoadingScreen";
import '../../../../App.css';
import WarningIcon from "../../../../components/UIComponents/icons/Warning";

const CampaignSetup = () => {
    const { campaignCode } = useParams();
    const accessToken = sessionStorage.getItem("OSACCESS");
    const navigate = useNavigate();
    const [campaign, setCampaign] = useState({});
    const [surveys, setSurveys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [noSurveys, setNoSurveys] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedSurvey, setSelectedSurvey] = useState(""); // State für Survey ID
    const [campaignTitle, setCampaignTitle] = useState(""); // State für Campaign Titel

    // Fetch Surveys Data
    const fetchSurveys = async () => {
        try {
            const response = await fetch("https://api.onesurvey.de/v1/admin/surveys", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                }
            });

            if (!response.ok) {
                throw new Error("Network response was not ok for survey info");
            }

            const surveyInfoResult = await response.json();
            setSurveys(surveyInfoResult);
            setLoading(false);
        } catch (error) {
            setNoSurveys(true);
            console.log(`Error ${error}`);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchSurveys();
    }, []);

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"80px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Etwas ist schiefgelaufen</h1>
                    <p>Bei der Erstellung der Kampagne ist leider ein Fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es noch einmal.</p>
                </div>
            </div>
        );
    }

    if (noSurveys) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <WarningIcon size={"80px"} fill={"none"} color={"#3a3a3a"}></WarningIcon>
                    <h1>Noch keine Umfrage verfügbar!</h1>
                    <p>Bitte erstellen Sie zuerst eine Umfrage um eine Kampagne erstellen zu können.</p>
                    <button className="btn btn-primary" onClick={()=>{navigate('/setup')}}>Jetzt erste Umfrage erstellen</button>
                </div>
            </div>
        );
    }

    if (loading) {
        return (
            <LoadingScreen />
        );
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        if (!selectedSurvey || !campaignTitle) {
            setError("Bitte füllen Sie alle Felder aus.");
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch("https://api.onesurvey.de/v1/campaigns/add", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    surveyId: selectedSurvey,
                    title: campaignTitle,
                }),
            });

            if (!response.ok) {
                throw new Error("Fehler beim Erstellen der Kampagne");
            }

            // Nach erfolgreicher Erstellung navigieren
            navigate('/campaigns');
        } catch (error) {
            setError(error.message);
            console.log("Error: ", error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/campaigns/`}> OneSurvey Campaigns &gt;</a>
                    <a href={`/campaign-setup`}> Setup</a>
                </span>
                <h1>Kampagne erstellen</h1>
                <p>Bitte füllen Sie die folgenden Felder aus, um eine neue Kampagne zu erstellen.</p>
                {error && (
                    <div className="error-message">
                        <WarningIcon size={"20px"} fill={"none"} color={"#ff0000"} />
                        <span>{error}</span>
                    </div>
                )}
                <div className="survey-section action-card">
                    <div className="spacer"></div>
                    <h3>Bitte wählen Sie eine Umfrage aus</h3>
                    <form onSubmit={handleSubmit}>
                        <select
                            className="dropdown"
                            name="survey"
                            style={{ width: "100%" }}
                            value={selectedSurvey}
                            onChange={(e) => setSelectedSurvey(e.target.value)}
                            required
                        >
                            <option value="" disabled>
                                Bitte wählen Sie eine Umfrage aus.
                            </option>
                            {surveys.map((survey) => (
                                <option key={survey.id} value={survey.id}>
                                    {survey.title}
                                </option>
                            ))}
                        </select>
                        <div className="spacer4"></div>
                        <h3>Bitte geben Sie Ihrer Kampagne einen aussagekräftigen Namen</h3>
                        <div>
                            <input
                                type="text"
                                placeholder="z.B. Kundenzufriedenheitsumfrage der Musterfirma GmbH"
                                name="campaignTitle"
                                style={{ width: "99%" }}
                                value={campaignTitle}
                                onChange={(e) => setCampaignTitle(e.target.value)}
                                required
                            />
                        </div>
                        <div className="spacer4"></div>
                        <span style={{ display: "flex", alignContent: "center", gap: "10px" }}>
                            <div>
                                <button id="btn-primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? 'Kampagne wird erstellt' : 'Kampagne erstellen'}
                                </button>
                            </div>
                        </span>
                    </form>
                    <div className="spacer"></div>
                </div>
            </div>
        </div>
    );
};

export default CampaignSetup;
