import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import WelcomePage from "./sites/Home/Home";
import PrivateRoute from "./helpers/PrivateRoute";

import OpenSurvey from "./sites/Open/OpenSurvey";
import Survey from "./sites/Open/SurveyV2/Survey";
import SurveyCheckout from "./sites/Open/SurveyCheckout";
import Impressum from "./sites/Open/Impressum";
import NotFound from "./sites/Open/NotFound";

import MySurveys from "./sites/Portal/SurveyManagement/MySurveys";
import MySurvey from "./sites/Portal/SurveyManagement/MySurvey/MySurvey";
import AddSurvey from "./sites/Portal/SurveyManagement/Setup/AddSurvey";
import Design from "./sites/Portal/SurveyManagement/MySurvey/DesignSurvey/Design";
import EditSurvey from "./sites/Portal/SurveyManagement/MySurvey/EditSurvey/EditSurvey";
import PortalAdmin from "./sites/Admin/Admin-Settings";

import Login from "./sites/Auth/Login/Login";
import Logout from "./sites/Auth/Logout";
import SetupDesignSettings from "./sites/Portal/SurveyManagement/Setup/DesignSurvey";
import SetupAddFields from "./sites/Portal/SurveyManagement/Setup/AddFields";
import SetupPublishSurvey from "./sites/Portal/SurveyManagement/Setup/PublishSurvey";
import SetupCheckout from "./sites/Portal/SurveyManagement/Setup/Checkout";
import SurveyFields from "./sites/Portal/SurveyManagement/MySurvey/Fields/SurveyFields";
import AddField from "./sites/Portal/SurveyManagement/MySurvey/Fields/AddField/AddField";
import AddFieldCheckout from "./sites/Portal/SurveyManagement/MySurvey/Fields/AddField/AddFieldCheckout";
import AddOption from "./sites/Portal/SurveyManagement/MySurvey/Fields/AddField/AddOption";
import EditField from "./sites/Portal/SurveyManagement/MySurvey/Fields/EditField/EditField";
import ListOptions from "./sites/Portal/SurveyManagement/MySurvey/Fields/EditOptions/ListOptions";
import EditOption from "./sites/Portal/SurveyManagement/MySurvey/Fields/EditOptions/EditOption";

import Dashboard from "./sites/Portal/Dashboard/Dashboard";
import SelectSurvey from "./sites/Portal/Dashboard/SelectSurvey/SelectSurvey";
import SurveyDashboard from "./sites/Portal/Dashboard/SurveyDashboard/SurveyDashboard";
import Register from "./sites/Auth/Register/Registration";
import RegisterCheckout from "./sites/Auth/Register/Checkout";
import ResetPassword from "./sites/Auth/ResetPassword/ResetPassword";
import SendPasswordReset from "./sites/Auth/ResetPassword/SendPasswordReset";
import MyAccount from "./sites/Portal/Account/MyAccount";
import EditPersonalData from "./sites/Portal/Account/EditAccount/EditPersonalData";
import EditAdressInformation from "./sites/Portal/Account/EditAccount/EditAdressInformation";
import ChangePassword from "./sites/Portal/Account/AccountSecurity/ChangePassword";
import AccountSettings from "./sites/Portal/Account/AccountSettings/AccountSettings";
import AccountSetup from "./sites/Portal/Account/AccountSetup";
import SendVerificationCode from "./sites/Auth/AccountVerify/SendVerificationCode";
import AccountVerification from "./sites/Auth/AccountVerify/AccountVerification";
import Entry from "./sites/Portal/Dashboard/Entry/Entry";
import EntryPrint from "./sites/Portal/Dashboard/Entry/EntryPrint";
import AccountBlocked from "./sites/Auth/Login/AccountBlocked";
import Maintenance from "./sites/Open/MaintenanceMode";
import Shop from "./sites/Portal/Shop/Shop";
import ProductPage from "./sites/Portal/Shop/Product/ProductPage";
import ProductPageOrder from "./sites/Portal/Shop/Product/ProductPage-Order";
import ProductPageCheckout from "./sites/Portal/Shop/Product/ProductPage-Checkout";
import MySubscriptions from "./sites/Portal/Account/Subscriptions/MySubscriptions";
import MySubscription from "./sites/Portal/Account/Subscriptions/SubscriptionDetails";
import Datenschutz from "./sites/Open/Datenschutz";
import Campaigns from "./sites/Portal/Campaigns/Campaigns";
import Campaign from "./sites/Portal/Campaigns/MyCampaign/Campaign";
import CampaignSetup from "./sites/Portal/Campaigns/Setup/CampaignSetup";
import CampaignMailTemplate from "./sites/Portal/Campaigns/MyCampaign/MailTemplate/MailTemplate";
import EditCampaign from "./sites/Portal/Campaigns/MyCampaign/Edit/EditCampaign";
import SetupSurveyLayout from "./sites/Portal/SurveyManagement/Setup/Layout";
import SetupEditField from "./sites/Portal/SurveyManagement/Setup/EditField";
import SurveyFieldsRearrange from "./sites/Portal/SurveyManagement/MySurvey/Fields/RearrangeFields/SurveyFieldsRearrange"
import MFASetup from "./sites/Portal/Account/AccountSecurity/MFASetup";
import ContractDetails from "./sites/Portal/Account/Subscriptions/Contract/ContractDetails";
function App() {

    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/create-account" element={<Register />} />
                    <Route path="/create-account/checkout" element={<RegisterCheckout />} />
                    <Route path="/sites/account-deactivated" element={<AccountBlocked />} />
                    <Route path="/sites/maintenance" element={<Maintenance />} />
                    <Route path="/reset-password" element={<SendPasswordReset />} />
                    <Route path="/action/password-reset/:id/:code" element={<ResetPassword/>} />

                    <Route path="/account-verify/:id/:code" element={<AccountVerification/>} />
                    <Route path="/resend-verify-code" element={<SendVerificationCode/>} />

                    <Route exact path="/s" element={<OpenSurvey />} />
                    <Route path="/s/:id" element={<Survey />} />

                    <Route path="/s/:id/checkout" element={<SurveyCheckout />} />
                    <Route path="/datenschutz" element={<Datenschutz />} />
                    <Route path="/impressum" element={<Impressum />} />
                    <Route path="/logout" element={<Logout />} />

                    <Route element={<PrivateRoute />}>
                        <Route exact path="/" element={<WelcomePage />} />

                        <Route exact path="/e-shop" element={<Shop/>} />
                        <Route path="/e-shop/:productCode" element={<ProductPage/>} />
                        <Route path="/e-shop/:productCode/order" element={<ProductPageOrder/>} />
                        <Route path="/e-shop/:productCode/checkout" element={<ProductPageCheckout/>} />

                        <Route exact path="/setup" element={<AddSurvey />} />
                        <Route exact path="/setup/:surveyCode/design" element={<SetupDesignSettings />} />
                        <Route exact path="/setup/:surveyCode/add-fields" element={<SetupAddFields />} />
                        <Route exact path="/setup/:surveyCode/add-fields/edit/:fieldId" element={<SetupEditField />} />
                        <Route exact path="/setup/:surveyCode/layout" element={<SetupSurveyLayout />} />
                        <Route exact path="/setup/:surveyCode/publish" element={<SetupPublishSurvey />} />
                        <Route exact path="/setup/:surveyCode/checkout" element={<SetupCheckout />} />

                        <Route exact path="/mysurveys" element={<MySurveys />} />
                        <Route path="/mysurveys/:surveyCode" element={<MySurvey />} />
                        <Route path="/mysurveys/:surveyCode/edit" element={<EditSurvey />} />

                        <Route path="/mysurveys/:surveyCode/fields" element={<SurveyFields />} />
                        <Route path="/mysurveys/:surveyCode/fields/positions" element={<SurveyFieldsRearrange />} />
                        <Route path="/mysurveys/:surveyCode/fields/add" element={<AddField />} />
                        <Route path="/mysurveys/:surveyCode/fields/add/checkout" element={<AddFieldCheckout />} />
                        <Route path="/mysurveys/:surveyCode/fields/:fieldId/add-options" element={<AddOption onFieldAdd={true}/>} />
                        <Route path="/mysurveys/:surveyCode/fields/:fieldId/edit" element={<EditField />} />
                        <Route path="/mysurveys/:surveyCode/fields/:fieldId/options" element={<ListOptions />} />
                        <Route path="/mysurveys/:surveyCode/fields/:fieldId/options/:optionId/edit" element={<EditOption />} />
                        <Route path="/mysurveys/:surveyCode/fields/:fieldId/options/add" element={<AddOption onFieldAdd={false} />} />

                        <Route exact path="/mysurveys/:surveyCode/design-settings" element={<Design />} />

                        <Route exact path="/dashboard" element={<Dashboard />} />
                        <Route exact path="/dashboard/:surveyCode" element={<SurveyDashboard />} />
                        <Route exact path="/data/entry/:entryId" element={<Entry />} />
                        <Route exact path="/data/entry/:entryId/print" element={<EntryPrint />} />
                        <Route exact path="/panel/select-survey" element={<SelectSurvey />} />

                        <Route exact path="/myaccount" element={<MyAccount />} />
                        <Route exact path="/myaccount/personal-information" element={<EditPersonalData />} />
                        <Route exact path="/myaccount/adress-information" element={<EditAdressInformation />} />
                        <Route exact path="/myaccount/change-password" element={<ChangePassword />} />
                        <Route exact path="/myaccount/mfa-setup" element={<MFASetup />} />
                        <Route exact path="/myaccount/settings" element={<AccountSettings />} />
                        <Route exact path="/myaccount/subscriptions" element={<MySubscriptions />} />
                        <Route exact path="/myaccount/subscriptions/:subscriptionId" element={<MySubscription />} />
                        <Route exact path="/myaccount/subscriptions/contract/:contractCode" element={<ContractDetails />} />
                        <Route exact path="/account-setup" element={<AccountSetup />} />

                        <Route exact path="/campaigns" element={<Campaigns />} />
                        <Route exact path="/campaigns/:campaignCode" element={<Campaign />} />
                        <Route exact path="/campaigns/:campaignCode/edit" element={<EditCampaign />} />
                        <Route exact path="/campaigns/:campaignCode/mail-template" element={<CampaignMailTemplate />} />

                        <Route exact path="/campaign-setup" element={<CampaignSetup />} />
                        <Route exact path="/campaign-setup/checkout" element={<Campaign />} />

                        <Route exact path="/portaladmin" element={<PortalAdmin />} />
                    </Route>
                    <Route path='*' element={<NotFound />}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;

