import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import Toast from "../../../../components/UIComponents/tools/Toast";
import {QRCodeSVG} from 'qrcode.react';

const MFASetup = () => {
    const accessToken = sessionStorage.getItem('OSACCESS');
    const [MFAactivated, setMFAactivated] = useState(false)
    const [totpUrl, setTotpUrl] = useState('');
    const [error, setError] = useState('');
    const [toast, setToast] = useState({ show: false, message: '', type: '' });
    const navigate = useNavigate();

    const setupTotp = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: null
            });

            if (!response.ok) {
                const errorData = await response.text();
                showToast(errorData, 'error');
                throw new Error(errorData || 'Failed to set up TOTP.');
            }

            const data = await response.json();
            setTotpUrl(data.totpUrl);
            setMFAactivated(true);
            showToast('TOTP wurde erfolgreich eingerichtet. Bitte scannen Sie den QR-Code.', 'success');
        } catch (err) {
            setError(err.message);
        }
    };

    const deactivateTotp = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup/deactivate', {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
                body: null
            });

            if (!response.ok) {
                const errorData = await response.text();
                showToast(errorData, 'error');
                throw new Error(errorData || 'Failed to deactivate up TOTP.');
            }
            setTotpUrl('');
            setMFAactivated(false);
            showToast('TOTP wurde deaktiviert.', 'success');
        } catch (err) {
            setError(err.message);
        }
    };

    const validateStatus = async () => {
        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/totp-setup/validate', {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                setMFAactivated(true);
            }

        } catch (err) {
            setError(err.message);
        }
    };

    useEffect(() => {
        validateStatus();
    }, []);

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/mfa-setup`}> MFA Einstellungen</a>
                </span>
                <h1>Multifaktor-Authentifizierung einrichten</h1>
                <p>Schützen Sie Ihr Konto mit der Multifaktor-Authentifizierung (MFA). Durch die Aktivierung von MFA fügen Sie eine zusätzliche Sicherheitsebene hinzu, die sicherstellt, dass nur Sie auf Ihr Konto zugreifen können. Scannen Sie den QR-Code mit einer Authentifizierungs-App, um die TOTP (Time-based One-Time Password)-Authentifizierung zu aktivieren.</p>
                <div className="spacer5"></div>
                <p>
                    {MFAactivated ? "MFA ist für diesen Account aktiv." : "MFA deaktiviert."}
                </p>
                <div className="">
                    {MFAactivated ?
                        <button className="btn-danger" onClick={()=>{deactivateTotp()}}>MFA deaktivieren</button>
                        :
                        <button className="btn btn-primary" onClick={()=>{setupTotp()}}>MFA einrichten</button>
                    }
                    {totpUrl && (
                        <div className="qr-code-section">
                            <h2>QR-Code zum Scannen:</h2>
                            <a href={totpUrl}><QRCodeSVG value={totpUrl} size={256} /></a>
                            <p>Name: OneCoding IDM</p>

                        </div>
                    )}
                </div>

            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
}

export default MFASetup;
