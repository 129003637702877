import React, { useState, useEffect } from "react";
import Header from "../../../../components/Header";
import '../../../../App.css';
import { useNavigate } from "react-router-dom";
import './AccountSettings.css';
import Toast from "../../../../components/UIComponents/tools/Toast";

const AccountSettings = () => {
    const accessToken = sessionStorage.getItem('OSACCESS');
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({ id: '' });
    const [error, setError] = useState(null);
    const [message, setMessage] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [loginNotify, setLoginNotify] = useState(false);
    const [toast, setToast] = useState({ show: false, message: '', type: '' });

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const account = await response.json();
                    setUserInfo({ id: account.id });
                } else {
                    navigate('/login');
                }
            } catch (error) {
                setError(error);
                showToast('Fehler beim Abrufen der Profilinformationen.', 'error')
                console.error("Failed to load user profile", error);
            }
        };

        const fetchAccountSettings = async () => {
            try {
                const response = await fetch('https://api.onesurvey.de/v1/account/settings', {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': 'application/json'
                    }
                });

                if (response.ok) {
                    const settings = await response.json();
                    setLoginNotify(settings.loginNotify);
                } else {
                    showToast('Fehler beim Abrufen der Profileinstellungen.', 'error')
                    setError("Failed to load account settings");
                }
            } catch (error) {
                setError(error);
                console.error("Failed to load account settings", error);
            }
        };

        fetchUserInfo();
        fetchAccountSettings();
    }, [accessToken, navigate]);

    const handleDeleteAccount = async (event) => {
        event.preventDefault();

        if (!isChecked) {
            showToast("Bitte bestätigen Sie, dass Sie Ihr Konto löschen möchten.", 'error');

            return;
        }

        try {
            const response = await fetch(`https://api.onesurvey.de/v1/account`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.ok) {
                console.log("Account successfully deleted");
                navigate('/login');
            } else {
                const text = await response.text();
                if (text === "Account couldn't deleted - remove Abonnements first.") {
                    showToast("Das Konto konnte nicht gelöscht werden - es sind noch aktive Abonnements erhalten. Nach Deaktivierung dieser kann der Account gelöscht werden.", 'error');
                } else {
                    showToast(text, 'error');
                }
                console.error("Failed to delete account", response);
            }
        } catch (error) {
            console.error(`Error ${error}`);
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleLoginNotifyChange = async (e) => {
        const newLoginNotify = e.target.checked;
        setLoginNotify(newLoginNotify);

        try {
            const response = await fetch('https://api.onesurvey.de/v1/account/settings', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ loginNotify: newLoginNotify })
            });

            if (!response.ok) {
                throw new Error("Failed to update login notification setting");
            }
            else{
                showToast('Benachrichtigungseinstellungen angepasst', 'success');
            }
        } catch (error) {
            setError(error);
            showToast('Fehler beim Anpassen der Benachrichtigungseinstellungen', 'error');
            console.error("Failed to update login notification setting", error);
        }
    };

    if (error) {
        return (
            <div>
                <Header />
                <div className="app-container">
                    <h1>Weitere Accounteinstellungen</h1>
                    <p>Fehler: Daten konnten leider nicht geladen werden. Bitte versuchen Sie es später noch einmal.</p>
                </div>
            </div>
        );
    }

    const showToast = (message, type) => {
        setToast({ show: true, message, type });
    };

    const hideToast = () => {
        setToast({ show: false, message: '', type: '' });
    };

    return (
        <div>
            <Header />
            <div className="app-container">
                <span className="app-breadcrumbs">
                    <a href='/'>Home &gt;</a>
                    <a href={`/myaccount/`}> Mein Account &gt;</a>
                    <a href={`/myaccount/settings`}> Einstellungen</a>
                </span>
                <h1>Weitere Accounteinstellungen</h1>
                <div className="spacer"></div>
                <section className="action-card settings-section">
                    <h3>Benachrichtigungen</h3>
                    <label className="checkbox-label">
                        <input
                            className="checkbox-input"
                            type="checkbox"
                            checked={loginNotify}
                            onChange={handleLoginNotifyChange}
                        />
                        Login-Warnung per E-Mail
                    </label>
                </section>
                <div className="spacer"></div>
                <section className="action-card settings-section">
                    <h3>Ihr Konto löschen</h3>
                    <p>Sie können Ihren OneSurvey Account löschen, <strong>wenn Sie Ihre aktiven Abonnements gekündigt haben und diese am Enddatum ausgelaufen sind</strong>. Bei einer Account-Löschung werden alle Daten aus unseren Systemen entfernt. Eine Wiederherstellung der gelöschten Accountdaten ist nicht möglich.</p>
                    <div className="warn-container">
                        <p id="warn-container-badge">Wichtige Information</p>
                        <h3 className="warn-container-title">Achtung! Ihre Umfragen, Einträge & Daten werden ebenfalls unwiderruflich gelöscht.</h3>
                        <p id="warn-container-description">Mit dem Löschen Ihres Accounts werden sofort alle damit verbundenen Daten unwiderruflich entfernt. Dies umfasst alle Daten zu Umfragen, Einträgen und zu Ihrem Account. Stellen Sie sicher, dass Sie eine Sicherungskopie wichtiger Daten erstellt haben, bevor Sie fortfahren.
                        </p>
                    </div>
                    <form onSubmit={handleDeleteAccount}>
                        <label className="checkbox-label">
                            <input
                                className="checkbox-input"
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleCheckboxChange}
                            />
                            Ich bestätige, dass ich mein Konto, inkl. alle meine Umfragen und dessen Einträge und Daten löschen möchte.
                        </label>
                        <div className="spacer3"></div>
                        <button className="btn-danger" type="submit">Account löschen</button>
                    </form>
                    {message && <p className="form-message">{message}</p>}
                </section>
                <button className="btn-primary" onClick={()=>navigate('/myaccount')}>Zurück</button>
            </div>
            {toast.show && (
                <Toast message={toast.message} type={toast.type} onClose={hideToast} />
            )}
        </div>
    );
}

export default AccountSettings;
